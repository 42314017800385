import * as React from 'react'

export { default as ImageZoom } from './Image.Zoom'
export { default as ImagePlaceholder } from './Image.Placeholder'

const Image: React.FC<{ src: any; alt?: any }> = ({ src, alt, ...props }) => {
  const imgProps = {
    alt,
    ...props
  }

  if (!src) {
    return null
  }

  // Create a bool to tell us if the src is a string (vanilla img) or object (Gatsby)
  const isGatsby = typeof src !== 'string'

  // Now we need to calculate the prop that will set the src of the image.
  // This will either be src (for strings), fixed or fluid. Defaults to src
  const keyForSrc =
    // If src is an object with a width and height then we want fixed={src}
    (isGatsby && src.width && src.height && 'fixed') ||
    // The only other Gatsby option would be fluid
    (isGatsby && 'fluid') ||
    // Otherwise src is a string so set a vanilla src prop
    'src'

  // todo : throw an exception if it is neither src nor fixed nor fluid

  // Now set either src, fixed or fluid to the src prop
  imgProps[keyForSrc] = src

  // Retrun either the GatsbyImg component or a regular img tag with the spread props
  return isGatsby ? (
    <picture>
      <img src={src[keyForSrc]} alt={alt} />
    </picture>
  ) : (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img {...imgProps} />
  )
}

export default Image
